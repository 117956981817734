<template>
  <div class="d-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
  </div>
  <effect-stats />
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Search from "@/components/Search.vue";
import EffectStats from "@/components/stats/EffectStats.vue";

export default defineComponent({
  name: "EffectDetails",
  components: {
    Search,
    EffectStats,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    store.dispatch("effects/fetchEffectById", route.params.eff_id);
  },
});
</script>

<style>
</style>
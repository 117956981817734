<template>
  <router-link
    v-if="!accountLabels.data[account]"
    :to="{
      name: 'DirectoryUpdateRoute',
      query: {
        account: account,
      },
    }"
    ><i
      class="bi bi-plus-circle fs-5 text-success"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title=""
      :data-bs-original-title="$t('operation_types.add_label')"
      v-tooltip
    ></i
  ></router-link>
  <i
    v-else-if="
      ['Scam', 'Hack'].includes(accountLabels.data[account].label.name)
    "
    class="bi bi-exclamation-triangle-fill text-danger fs-5"
    data-bs-toggle="tooltip"
    data-bs-placement="top"
    :data-bs-original-title="
      $t('txn_summary.scam_tooltip', {
        type: accountLabels.data[account].label.name,
      })
    "
    v-tooltip
  ></i>
  <i
    v-else-if="!accountLabels.data[account].label.verified"
    class="bi bi-person-circle fs-5"
    data-bs-toggle="tooltip"
    data-bs-placement="top"
    :data-bs-original-title="
      $t('operation_types.unverified_user_defined_label')
    "
    v-tooltip
  ></i>
  <i
    v-else-if="accountLabels.data[account].label.verified"
    class="bi bi-patch-check-fill text-primary fs-5 text-truncate"
    data-bs-toggle="tooltip"
    data-bs-placement="top"
    :data-bs-original-title="$t('operation_types.verified_account')"
    v-tooltip
  ></i>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "AccountLabel",
  props: {
    account: String,
  },
  setup() {
    const store = useStore();
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    return { accountLabels };
  },
});
</script>
<template>
  <effect-stats-skeleton v-if="loading" />
  <error v-else-if="error" />
  <template v-else>
    <div class="card mb-2">
      <div class="card-body">
        <ul class="list-group">
          <effect-info :effectDetails="effDetails" :details="true" />
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-credit-card me-3"></i
              >{{ $t("effect_stats.effect_type") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span class="align-self-center small font-monospace">{{
                splitAndCapitalize(effDetails.type, "_")
              }}</span>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-clock me-3"></i
              >{{ $t("effect_stats.created_at") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span class="align-self-center small font-monospace">{{
                formatDate(effDetails.created_at) +
                " · " +
                formatDateElapsedTime(effDetails.created_at)
              }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { formatDate, formatDateElapsedTime } from "@/utils/date";
import { splitAndCapitalize } from "@/utils/string.js";
import Error from "@/components/Error.vue";
import EffectStatsSkeleton from "@/components/skeletons/stats/EffectStatsSkeleton.vue";
import EffectInfo from "@/components/effects/EffectInfo.vue";

export default defineComponent({
  name: "EffectStats",
  components: {
    Error,
    EffectStatsSkeleton,
    EffectInfo,
  },
  setup() {
    const store = useStore();
    const effDetails = computed(() => store.getters["effects/getSelectedEff"]);
    const loading = computed(() => store.getters["effects/getLoadingStatus"]);
    const error = computed(() => store.getters["effects/getError"]);
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);

    return {
      formatDate,
      formatDateElapsedTime,
      loading,
      error,
      effDetails,

      splitAndCapitalize,
      accountLabels,
    };
  },
});
</script>
